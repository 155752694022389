<template>
  <div class="feedback">
    <div class="table"
         v-if="!showInfo">
      <div class="head">
        我的反馈
      </div>
      <el-table :data="tableData"
                style="width: 100%">
        <el-table-column prop="question_id"
                         align="center"
                         label="题目ID">
          <template slot-scope="{row}">
            <span class="text">
              <!-- {{$rands(row.question_id)}} -->
              {{row.question_id}}

            </span>
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         align="center"
                         label="状态">
          <template slot-scope="{row}">
            <span class="text"
                  :style="{'color':getColor(row.status)}">
              {{getValue(row.status)}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop=""
                         align="center"
                         label="">
          <template slot-scope="{row}">
            <div class="btn"
                 @click="getInfo(row)">
              <img src="@/assets/info.png"
                   alt="">
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-else>
      <div class="head">
        题目详情
      </div>

      <div class="warp">
        <div class="topic_head">题目内容</div>
        <div v-html="info.question_content"
             class="btm_bdr">
        </div>
      </div>
      <div class="warp">
        <div class="topic_head">
          题目答案
        </div>
        <div v-html="info.question_answer"
             class="btm_bdr">
        </div>
      </div>

      <div class="warp">
        <div class="topic_head">
          题目解析
        </div>
        <div v-html="info.question_explain"
             class="btm_bdr"></div>
      </div>
      <el-row :gutter="10"
              style="margin-top:10rem"
              v-if="info.category == 1">
        <span class="comment">
          分数：{{info.user_score}}
        </span>
      </el-row>

      <el-row :gutter="10"
              style="margin-top:10rem"
              v-if="info.category == 1">
        <span class="comment">
          评语：{{info.comment_to_user}}
        </span>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <div :class="`status_btn ${getStatusClass(info.status)}`">
            {{getValue(info.status)}}
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10"
              v-if="info.teacher_comment">
        <span class="comment">
          未通过原因：{{info.teacher_comment}}
        </span>
      </el-row>
      <div class="img_wrap">
        <el-image v-for="(item,index) in info.counter_image"
                  :key="index"
                  style="width: 100rem; height: 100rem;margin-right:10rem;border-radius:10rem"
                  :src="item"
                  :preview-src-list="info.counter_image">
        </el-image>
      </div>

      <div style="width:100rem;height:30rem"></div>
    </div>

  </div>
</template>

<script>
import { getFeeadbackList, getFeeadbackInfo } from '@/api/feeakback.js'
export default {
  data () {
    return {
      tableData: [],
      showInfo: false,
      info: {},
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getValue (status) {
      var str = ''
      switch (status) {
        case -1:
          str = '未通过'
          break;
        case 0:
          str = '待审核'
          break;
        case 1:
          str = '审核通过'
          break;
        case 2:
          str = '修改完成'
          break;
      }
      return str;
    },
    getColor (status) {
      var color = ''
      switch (status) {
        case -1:
          color = '#FF664E'
          break;
        case 0:
          color = '#1584F0'
          break;
        case 1:
          color = '#2ACE84'
          break;
        case 2:
          color = '#2ACE84'
          break;
      }
      return color;
    },
    getStatusClass (status) {
      var classname = '';
      switch (status) {
        case -1:
          classname = 'wtg'
          break;
        case 0:
          classname = 'wsh'
          break;
        case 1:
          classname = 'tg'
          break;
        case 2:
          classname = 'tg'
          break;
      }
      return classname
    },
    async getList () {
      const { data } = await getFeeadbackList()
      this.tableData = data.lst;
    },
    async getInfo (row) {
      let params = {
        question_error_id: row.question_error_id
      }
      const { data } = await getFeeadbackInfo(params)
      this.showInfo = true
      this.info = data
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback {
  width: 70%;
  margin-top: 30rem;
  margin-left: 80rem;
  .head {
    font-size: 24rem;
    font-weight: 800;
    color: #000000;
    display: flex;
    justify-content: center;
    margin-bottom: 15rem;
  }
}
::v-deep .el-table__header {
  background: #ffffff !important;
}

::v-deep [data-v-36a45d9e] .el-table tr {
  font-size: 24rem;
  font-weight: 500;
  color: #666666;
}
::v-deep .el-table__empty-text {
  font-size: 24rem;
  font-weight: 500;
  color: #666666;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    width: 180rem;
    height: 60rem;
  }
}

::v-deep .el-table tr {
  box-shadow: 0rem 0rem 0rem 0rem rgba(229, 229, 229, 1);
  border-radius: 5rem;
  font-size: 16rem;
  font-weight: 500;
  color: #666666;
}

::v-deep .el-table th,
.el-table tr {
  background: none !important;
}
::v-deep .el-table tr {
  background: none !important;
}
::v-deep .el-table th,
::v-deep .el-table,
.el-table__expanded-cell {
  background: none !important;
}
::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background: none;
}
::v-deep .el-table td,
.el-table th.is-leaf {
  border-bottom: none;
}

.text {
  font-size: 24rem;
  font-weight: bold;
  color: #000000;
}
.warp {
  .topic_head {
    font-size: 14rem;
    font-weight: bold;
    color: #000000;
    border-left: 3rem solid #37a0f4;
    text-indent: 7rem;
    height: 14rem;
    line-height: 14rem;
    margin-bottom: 10rem;
  }
}
.btm_bdr {
  border-bottom: 1rem solid #cccccc;
  margin-bottom: 10rem;
  font-size: 14rem;
}
.status_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100rem;
  height: 35rem;
  border-radius: 18rem;
  font-size: 16rem;
  font-weight: bold;
  margin-top: 10rem;
}
.wsh {
  background: #d8e6fc;
  color: #007af0;
}
.wtg {
  background: #fedddd;
  color: #ff664e;
}
.tg {
  background: #e4f9ed;
  color: #2ace84;
}
.comment {
  font-size: 14rem;
  font-weight: bold;
  color: #000000;
}
.img_wrap {
  display: flex;
  flex-wrap: wrap;
}
</style>