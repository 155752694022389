import { instance } from '@/utils/http.js'

// 获取反馈列表
export const getFeeadbackList = function (params) {
  return instance({
    url: '/api/v1/error/lst',
    method: 'get',
    params
  })
}

//获取反馈详情
export const getFeeadbackInfo = function (params) {
  return instance({
    url: '/api/v1/error/detail',
    method: 'get',
    params
  })
}